import React from "react";
import { useQuery } from "@tanstack/react-query";
import cartabasicaIcon from "../../icons/arrow.svg";
import getIcon from "../../utils/getIcon";
import MenuSection from "../menu/MenuSection";
import CartaSection from "../menu/CartaSection";
import { fetchMenuAndCartaData } from "../../api/queries";
import "./CartaBasica.css";

const CartaBasica = ({ onClose, restaurant, restaurant_id }) => {
  // Esta query usará los datos precargados si están disponibles
  const { data, isLoading, error } = useQuery({
    queryKey: ["menuAndCarta", restaurant_id],
    queryFn: () => fetchMenuAndCartaData(restaurant_id),
  });

  if (isLoading) {
    return (
      <div className="carta-basica">
        <div className="carta-container">
          <div>Cargando datos...... un momento</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="carta-basica">
        <div className="carta-container">
          <div>Error: {error.message}</div>
        </div>
      </div>
    );
  }

  const { menuData, cartaData } = data;
  const RestaurantLogo = getIcon(restaurant["logo-dark"]);

  return (
    <div className="carta-basica">
      <div className="carta-container">
        <img
          src={cartabasicaIcon}
          alt="Carta Video"
          className="close-button"
          onClick={onClose}
        />
        <header className="carta-header">
          {RestaurantLogo ? (
            <RestaurantLogo className="restaurant-image" />
          ) : (
            <div>No logo available</div>
          )}
        </header>
        <MenuSection menu={menuData.menu} />
        <CartaSection carta={cartaData.carta} />
      </div>
    </div>
  );
};

export default CartaBasica;
