import React from "react";
import ReactPlayer from "react-player";
import "./VideoModal.css";

const VideoModal = ({ videoUrl, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="video-modal-overlay" onClick={onClose}>
      <div className="video-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="video-modal-close" onClick={onClose}>
          ×
        </button>
        <div className="video-player-wrapper">
          <ReactPlayer
            url={videoUrl} // La URL del video
            playing // Reproduce automáticamente
            loop // Activa el loop
            controls={false} // Desactiva los controles
            width="100%"
            height="100%"
            playsinline
            config={{
              youtube: {
                playerVars: {
                  playsinline: 1,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
