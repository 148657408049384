// src/icons/VideoBasica.js
import React from "react";

const VideoBasica = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="15"
    viewBox="0 0 24 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6969 2.22409C23.51 2.12462 23.2852 2.13482 23.1109 2.25215L18.2867 5.49905V9.49582L23.1109 12.7427C23.2877 12.86 23.5125 12.8728 23.6969 12.7708C23.8813 12.6713 24 12.4749 24 12.2607V2.73421C24 2.52251 23.8838 2.32612 23.6994 2.22664L23.6969 2.22409Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8542 0H2.28584C1.67965 0 1.09872 0.242306 0.669334 0.675906C0.23995 1.10951 0 1.69614 0 2.30828V12.6917C0 13.3039 0.23995 13.8905 0.669334 14.3241C1.09872 14.7577 1.67965 15 2.28584 15H14.8542C15.4603 15 16.0413 14.7577 16.4707 14.3241C16.9 13.8905 17.14 13.3039 17.14 12.6917V2.30828C17.14 1.69614 16.9 1.10951 16.4707 0.675906C16.0413 0.242306 15.4603 0 14.8542 0ZM11.1741 7.98079L7.74659 10.2891C7.56978 10.4064 7.34499 10.4191 7.16061 10.3171C6.97622 10.2177 6.85751 10.0213 6.85751 9.80701V5.19299C6.85751 4.97875 6.9737 4.7849 7.16061 4.68288C7.34499 4.5834 7.57231 4.59361 7.74659 4.71093L11.1741 7.01921C11.3307 7.12634 11.4267 7.30743 11.4267 7.49872C11.4267 7.69002 11.3307 7.87111 11.1716 7.97824L11.1741 7.98079Z"
      fill="currentColor"
    />
  </svg>
);

export default VideoBasica;
