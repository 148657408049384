// src/api/queries.js
export const fetchRestaurantData = async (restaurant_id) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}${restaurant_id}/?type=carta`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Error fetching restaurant data");
  }

  return response.json();
};

export const fetchMenuAndCartaData = async (restaurant_id) => {
  const [menuResponse, cartaResponse] = await Promise.all([
    fetch(`${process.env.REACT_APP_API_URL}${restaurant_id}/?type=menu`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
      },
    }),
    fetch(`${process.env.REACT_APP_API_URL}${restaurant_id}/?type=carta`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
      },
    }),
  ]);

  if (!menuResponse.ok || !cartaResponse.ok) {
    throw new Error("Error fetching menu or carta data");
  }

  const [menuData, cartaData] = await Promise.all([
    menuResponse.json(),
    cartaResponse.json(),
  ]);

  return { menuData, cartaData };
};
