// src/components/menu/MenuSection.js
import React, { useState } from "react";
import VideoBasica from "../../icons/VideoBasica";
import VideoModal from "./VideoModal";
import "./MenuSection.css";

const MenuSection = ({ menu }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const getOrderValue = (item, isCategory = false) => {
    const orderValue = isCategory ? item.orden : item.order;
    if (typeof orderValue === "string") {
      const numValue = parseInt(orderValue, 10);
      return isNaN(numValue) ? 0 : numValue;
    }
    return typeof orderValue === "number" ? orderValue : 0;
  };

  const handleVideoClick = (videoUrl, e) => {
    e.preventDefault();
    setSelectedVideo(videoUrl);
  };

  return (
    <section>
      <br />
      {menu
        .filter((menuItem) => menuItem.menu_activo)
        .sort((a, b) => getOrderValue(a) - getOrderValue(b))
        .map((menuItem, menuIndex) => (
          <div className="contenedor-menu" key={menuIndex}>
            <h2 className="menu-title">{menuItem.nombre}</h2>
            {menuItem.categoria
              .sort((a, b) => getOrderValue(a, true) - getOrderValue(b, true))
              .map((category, categoryIndex) => (
                <div key={categoryIndex}>
                  <h3>{category.nombre}</h3>
                  <ul>
                    {category.platos
                      .sort((a, b) => getOrderValue(a) - getOrderValue(b))
                      .map((dish, dishIndex) => (
                        <li className="dish-wrapper" key={dishIndex}>
                          <div className="menu-dish-item">
                            <div className="menu-dish-content">
                              <div className="menu-dish-name-container">
                                <span className="menu-dish-name">
                                  {dish.dishName}
                                </span>
                                {dish.video && (
                                  <button
                                    className="video-button"
                                    onClick={(e) =>
                                      handleVideoClick(dish.video, e)
                                    }
                                  >
                                    <VideoBasica className="video-icon" />
                                  </button>
                                )}
                              </div>
                              {dish.description && (
                                <div className="menu-dish-description">
                                  {dish.description}
                                </div>
                              )}
                              {dish.allergens && dish.allergens.length > 0 && (
                                <span className="menu-alergenos">
                                  {" a.: "}
                                  {dish.allergens.join(", ")}
                                </span>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            <h4 className="menu-price">{menuItem.precio}€</h4>
          </div>
        ))}
      <VideoModal
        isOpen={!!selectedVideo}
        onClose={() => setSelectedVideo(null)}
        videoUrl={selectedVideo}
      />
    </section>
  );
};

export default MenuSection;
